import React from 'react'
import Helmet from 'react-helmet'
import styles from './base.module.css'

import FeatureFeedback from '../../components/FeatureFeedback'
import FeatureRoadmap from '../../components/FeatureRoadmap'
import FeatureChangelog from '../../components/FeatureChangelog'
import SignupButton from '../../components/SignupButton'
import Layout from '../../layouts/index'
import Chrome from '../../components/Chrome'

import data from '../../../data/comparison.yaml'

export default class Page extends React.Component {
  renderItem = (item) => {
    return (
      <div className={styles.reason}>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.us}>{item.us}</div>
        <div className={styles.them}>{item.them}</div>
      </div>
    )
  }

  render() {
    const dataItems = data.headway

    return (
      <Layout>
        <div className={styles.root}>
          <Helmet title={'Headway alternative - Suggested'}></Helmet>

          <div className={styles.intro}>
            <h1>Headway alternative</h1>
            <h3>More than just a changelog.</h3>
          </div>

          <div className={styles.lead}>
            <p>
              As well as being an alternative for Headway, Suggested provides a
              complete set of tools for people who build products
            </p>
            <p>
              Suggested allows you to gather collect, manage and centralize
              feedback from customers. Enable customers to vote on feedback to
              help you prioritise which features to work on first.
            </p>
            <p>
              It also provides a public roadmap of your product development, to
              show customers how their feedback is informing product
              improvements.
            </p>
          </div>

          <div className={styles.section}>
            <FeatureFeedback />
          </div>

          <div className={styles.section}>
            <FeatureRoadmap />
          </div>

          <div className={styles.section}>
            <FeatureChangelog />
          </div>

          <div className={styles.comparison}>
            <h1>Why our customers love Suggested</h1>
            <div className={styles.items}>
              {dataItems.map((item) => this.renderItem(item))}
            </div>
          </div>

          <div className="signup-container">
            <SignupButton />
          </div>
        </div>
      </Layout>
    )
  }
}
